<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-text-field
        v-model="internalValue.login"
        :rules="$options.rules.login"
        label="login"
        type="text"
        readonly
        @input="change"
      />

      <v-text-field
        v-model="internalValue.firstname"
        :rules="$options.rules.firstname"
        label="Prénom"
        type="text"
        @input="change"
      />

      <v-text-field
        v-model="internalValue.lastname"
        :rules="$options.rules.lastname"
        label="Nom"
        type="text"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.partsType"
        label="Filtre des OF"
        :item-list="partTypeList"
        item-text="name"
        multiple
        chips
        deletable-chips
        clearable
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.role"
        :rules="$options.rules.role"
        label="Role"
        :item-list="roleList"
        item-text="name"
        @input="change"
      />

      <ag-password-field
        v-model="internalValue.password"
        :optional="optionalPassword"
        password-label="Mot de passe"
        confirm-label="Confirmation"
        no-icon
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'
import AgPasswordField from 'src/components/common/AgPasswordField'
import Repositories from 'src/repositories'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'UserForm',
  components: {
    AgPasswordField,
    AgAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    optionalPassword: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    roleList: [],
    partTypeList: [],
  }),
  computed: {
    isValidUser () {
      return this.mainFormValid
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        if (this.internalValue.role instanceof Object) {
          this.internalValue.role = this.internalValue.role._id
        }
        this.$emit('validate', this.isValidUser)
      },
    },
    isValidUser: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidUser)
      },
    },
    'internalValue.firstname': {
      immediate: false,
      handler () {
        this.setLogin()
      },
    },
    'internalValue.lastname': {
      immediate: false,
      handler () {
        this.setLogin()
      },
    },
  },
  async mounted () {
    const [roleList, partTypeList] = await Promise.all([
      await Repositories.role.getList(),
      await Repositories.partType.getList(),
    ])

    this.roleList = roleList
    this.partTypeList = partTypeList
  },
  methods: {
    change () {
      this.setLogin()
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidUser)
    },
    setLogin () {
      // eslint-disable-next-line max-len
      this.internalValue.login = this.internalValue.firstname.charAt(0).toLowerCase() + this.internalValue.lastname.toLowerCase()
    },
  },
  rules: {
    login: [
      FormRules.required('requis'),
      FormRules.noSpaces('Les espaces ne sont pas autorisées'),
    ],
    firstname: [
      FormRules.required('requis'),
    ],
    lastname: [
      FormRules.required('requis'),
    ],
    role: [
      FormRules.minLength('requis', { length: 1 }),
    ],
  },
}
</script>
